import { Report, Severity } from './types';

import { BaseReporter } from './BaseReporter';

declare const dtrum: {
    reportError(error: string | Error, parentActionId?: number): void;
};

export class DynatraceReporter extends BaseReporter {
    async processReport(report: Report): Promise<void> {
        try {
            dtrum?.reportError(
                report.severity === Severity.Error
                    ? report.error ?? new Error(report.message)
                    : report.message
            );
        } catch (e) {
            console.warn('Dynatrace reporting failed', { e });
        }
    }
}
