import { Report, Severity } from './types';

import { BaseReporter } from './BaseReporter';

const consoleSeverity: Record<
    Severity,
    keyof Pick<Console, 'error' | 'warn' | 'log'>
> = {
    [Severity.Error]: 'error',
    [Severity.Info]: 'log',
    [Severity.Warning]: 'warn',
};

export class ConsoleReporter extends BaseReporter {
    async processReport(report: Report): Promise<void> {
        const key = consoleSeverity[report.severity];
        console[key](report.message, {
            severity: report.severity,
            error: report.error,
            extra: report.extraData,
        });
    }
}
