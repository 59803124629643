import type {
    NotifiableError,
    OnErrorCallback,
    Stackframe,
} from '@bugsnag/js/types';

import { BaseReporter } from './BaseReporter';
import { Report } from './types';
import extractExtraErrorProps from '../util/extractExtraErrorFields';

const BANNER_REGEX =
    /^.*mx-key:\s*([a-zA-Z0-9]*)\s\|\s>\smx-version: ([a-zA-Z0-9]*).*/;

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        Bugsnag: {
            notify: (error: NotifiableError, onError?: OnErrorCallback) => void;
        };
    }
}

type Metadata = {
    appKey: string;
    version: string;
};

export class BugsnagReporter extends BaseReporter {
    private bundleMetadata: Record<string, Metadata | undefined> = {};

    // Bugsnag does not yet support batching (https://github.com/bugsnag/bugsnag-js/issues/1098)
    async processReport(report: Report): Promise<void> {
        if (window.Bugsnag) {
            this.notifyToBugsnag(report);
        } else {
            console.warn('Could not report, Bugsnag unavailable', report);
        }
    }

    private notifyToBugsnag(report: Report) {
        const { message, severity, extraData } = report;
        const error = report.error ?? new Error(message);
        const errorExtraData = extractExtraErrorProps(error);

        window.Bugsnag.notify(error, async (event) => {
            try {
                //The platform is responsible for setting the bugsnag key for oneservice. This logic is meant to be used by other MX teams that have different API keys
                const appBundle = event.errors[0]?.stacktrace.find((t) =>
                    t.file.endsWith('app-bundle.js')
                );

                if (appBundle) {
                    const releaseMetadata = await this.getBundleMetadata(
                        appBundle
                    );

                    if (releaseMetadata) {
                        event.apiKey = releaseMetadata.appKey;
                        event.app.version = releaseMetadata.version;
                    }
                }
            } catch (error) {
                event.addMetadata('bundleErrors', { error });
            }

            event.severity = severity;

            const originalError = event.errors[0].errorMessage;
            event.errors[0].errorMessage = message;
            if (originalError !== message) {
                event.addMetadata('originalError', 'message', originalError);
            }

            if (errorExtraData) {
                event.addMetadata('originalError', errorExtraData);
            }
            if (extraData) {
                event.addMetadata('moreInfo', extraData);
            }
        });
    }

    private async getBundleMetadata(
        appBundle: Stackframe
    ): Promise<Metadata | undefined> {
        let bundleMetadata = this.bundleMetadata[appBundle.file];
        if (!bundleMetadata) {
            const data = await (await fetch(appBundle.file)).text();
            const matches = data.match(BANNER_REGEX);
            bundleMetadata = matches
                ? { appKey: matches[1], version: matches[2] }
                : undefined;

            this.bundleMetadata[appBundle.file] = bundleMetadata;
        }

        return bundleMetadata;
    }
}
