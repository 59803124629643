const bannerRegex =
    /^.*mx-key:\s*([a-zA-Z0-9]*)\s\|\s>\smx-version: ([a-zA-Z0-9]*).*/;

type MetaData = { appKey: string; version: string };
const versionMap = new Map<string, MetaData | undefined>();

export const getBundleMetadata = async (
    url: string
): Promise<MetaData | undefined> => {
    if (versionMap.get(url) === undefined) {
        const data = await (await fetch(url)).text();
        const matches = data.match(bannerRegex);

        if (!matches) {
            versionMap.set(url, undefined);
        } else {
            versionMap.set(url, { appKey: matches[1], version: matches[2] });
        }
    }

    return versionMap.get(url);
};
