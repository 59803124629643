export enum Severity {
    Error = 'error',
    Info = 'info',
    Warning = 'warning',
}

export type Report = {
    message: string;
    severity: Severity;
    timestamp: string;
    error?: Error;
    extraData?: Record<string, unknown>;
};

export type Reporter = {
    processReport(report: Report): Promise<void>;
    teardown(): Promise<void>;
};
