const REDACTED_VALUE_REPLACEMENT = 'REDACTED';

export const objectRedactor = (obj: unknown, piiFields: string[]): unknown => {
    if (Array.isArray(obj)) {
        return obj.map((item) => objectRedactor(item, piiFields));
    }

    try {
        return Object.entries(obj as object).reduce<Record<string, unknown>>(
            (object, [key, value]) => {
                let redactedValue: unknown;

                if (piiFields.includes(key.toLowerCase())) {
                    redactedValue = REDACTED_VALUE_REPLACEMENT;
                } else if (Array.isArray(value)) {
                    redactedValue = value.map((item: unknown) =>
                        objectRedactor(item, piiFields)
                    );
                } else if (typeof value === 'object') {
                    redactedValue = objectRedactor(value, piiFields);
                } else {
                    redactedValue = value;
                }

                object[key] = redactedValue;
                return object;
            },
            {}
        );
    } catch (err) {
        return obj;
    }
};
