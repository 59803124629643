import { Layout } from '@soluto-private/mx-types';

const excluded = [
    '@soluto-private/mx-app-header',
    '@soluto-private/mx-app-footer',
    '@soluto-private/mx-app-navbar',
    '@soluto-private/mx-app-devtools',
];

const getMxFromLayout = () => {
    const route = window.location.pathname.slice(1).split('/')[0];
    const rawLayout = window.sessionStorage.getItem('mx-layout');
    if (rawLayout) {
        const layout = JSON.parse(rawLayout) as Layout;
        return layout?.routes
            ?.find((r) => r.path?.slice(1) === route)
            ?.routes?.map((r) => r.name)
            ?.filter((m) => m && !excluded.includes(m))?.[0];
    }
};

const getFullstorySessionUrl = (): string | undefined =>
    // @ts-expect-error window.FS object is not typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    window.FS?.getCurrentSessionURL?.(true);

export const getContext = () => ({
    currentUrl: window.location.href,
    currentPath: window.location.pathname,
    currentMx: getMxFromLayout(),
    fullstorySessionUrl: getFullstorySessionUrl(),
});
