export type { Monitor } from './Monitor';

import {
    BugsnagReporter,
    ConsoleReporter,
    DynatraceReporter,
    LoggingReporter,
    Severity,
} from './reporters';

import { ExecutorConfiguration } from './Executor';
import { Monitor } from './Monitor';
import { MonitorEventBus } from './MonitorEventBus';
import { wrapWithMonitor } from './util';

const eventBus = new MonitorEventBus();

const executorConfiguration: ExecutorConfiguration = {
    reporterConfigurations: [
        {
            reporter: new BugsnagReporter(),
            filteredSeverities: [Severity.Info],
        },
        {
            reporter: new DynatraceReporter(),
            filteredSeverities: [Severity.Info],
        },
        {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            reporter: new LoggingReporter(process.env.LOGGING_API_URL!),
        },
    ],
};
if (!['production', 'test', undefined].includes(process.env.NODE_ENV)) {
    executorConfiguration.reporterConfigurations.push({
        reporter: new ConsoleReporter(),
    });
}

export const monitor = new Monitor({
    eventBus,
    executorConfiguration,
});

export const wrapWithModuleName =
    (module: string) =>
    <T extends unknown[], U>(
        fn: (...args: T) => U,
        extra: Record<string, unknown> = {}
    ) =>
        wrapWithMonitor(fn, monitor, { ...extra, module });
