import { Report, Reporter, Severity } from './reporters';

import { MonitorEventBus } from './MonitorEventBus';

export type ReporterConfiguration = {
    reporter: Reporter;
    filteredSeverities?: Severity[];
};

export type ExecutorConfiguration = {
    reporterConfigurations: ReporterConfiguration[];
};

export class Executor {
    private reporterConfigurations: ReporterConfiguration[];

    constructor(
        { reporterConfigurations }: ExecutorConfiguration,
        private readonly eventBus: MonitorEventBus
    ) {
        this.reporterConfigurations = reporterConfigurations;

        this.eventBus = eventBus;
        this.eventBus.addReportEventListener(
            (e) => void this.handleReportEvent.bind(this)(e)
        );

        window.onbeforeunload = () => void this.teardown();
    }

    private async handleReportEvent(event: CustomEvent<Report>): Promise<void> {
        const report: Report = event.detail;
        await Promise.all(
            Object.values(this.reporterConfigurations)
                .filter((c) => !c.filteredSeverities?.includes(report.severity))
                .map(async (c) => await c.reporter.processReport(report))
        );
    }

    private async teardown(): Promise<void> {
        const reporters: Reporter[] = this.reporterConfigurations.map(
            (c) => c.reporter
        );

        this.reporterConfigurations = [];

        await Promise.all(
            Object.values(reporters).map(async (r) => await r.teardown())
        );

        this.eventBus.removeReportEventListener(
            (e) => void this.handleReportEvent.bind(this)(e)
        );
    }
}
