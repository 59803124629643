export const defaultPiiFields = [
    'FirstName',
    'LastName',
    'Email',
    'mdn',
    'phone',
].map((str) => str.toLowerCase());

export class RedactorConfig {
    public piiFields: string[];

    constructor(piiFields?: string[]) {
        this.piiFields = piiFields
            ? [
                  ...defaultPiiFields,
                  ...piiFields.map((str) => str.toLowerCase()),
              ]
            : defaultPiiFields;
    }
}
