import { RedactorConfig } from './RedactorConfig';
import { objectRedactor } from './objectRedactor';

export class Redactor {
    constructor(
        private readonly _config: RedactorConfig = new RedactorConfig()
    ) {}

    public redact<T extends string | object>(
        data: T,
        config?: RedactorConfig
    ): T {
        return objectRedactor(
            data,
            config?.piiFields || this._config.piiFields
        ) as T;
    }
}
