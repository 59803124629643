import type { Monitor } from '../Monitor';

export const wrapWithMonitor =
    <T extends unknown[], U>(
        fn: (...args: T) => U,
        monitor: Monitor,
        extra: Record<string, unknown> = {}
    ) =>
    (...args: T): U => {
        monitor.info(`Invoking ${fn.name}`, extra);
        return fn(...args);
    };
