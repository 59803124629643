import { Executor, ExecutorConfiguration } from './Executor';
import { Report, Severity } from './reporters';

import { MonitorEventBus } from './MonitorEventBus';
import { Redactor } from './redactor';

export type MonitorConfiguration = {
    eventBus: MonitorEventBus;
    executorConfiguration: ExecutorConfiguration;
    redactor?: Redactor;
};

export type MonitorReportOptions = {
    piiFields?: string[];
};

type PublishReportOptions = {
    error?: Error;
    extra?: Record<string, unknown>;
    piiFields?: string[];
};

export class Monitor {
    private readonly eventBus: MonitorEventBus;
    private readonly redactor: Redactor;

    constructor({
        eventBus,
        executorConfiguration,
        redactor = new Redactor(),
    }: MonitorConfiguration) {
        this.eventBus = eventBus;
        this.redactor = redactor;
        new Executor(executorConfiguration, eventBus);
    }

    /**
     * Reports an error
     *
     * @param message The error message
     * @param error The error
     * @param extra Extra data object to be attached to the report
     */
    error(
        message: string,
        error: Error,
        extra?: Record<string, unknown>,
        options?: MonitorReportOptions
    ) {
        this.publishReport(message, Severity.Error, {
            error,
            extra,
            piiFields: options?.piiFields,
        });
    }

    /**
     * Reports a warning
     *
     * @param message The warning message
     * @param error The error
     * @param extra Extra data object to be attached to the report
     */
    warning(
        message: string,
        error?: Error,
        extra?: Record<string, unknown>,
        options?: MonitorReportOptions
    ) {
        this.publishReport(message, Severity.Warning, {
            extra,
            error,
            piiFields: options?.piiFields,
        });
    }

    /**
     * Reports an informational message
     *
     * @param message The informational message
     * @param extra Extra data object to be attached to the report
     */
    info(
        message: string,
        extra?: Record<string, unknown>,
        options?: MonitorReportOptions
    ) {
        this.publishReport(message, Severity.Info, {
            extra,
            piiFields: options?.piiFields,
        });
    }

    private publishReport(
        message: string,
        severity: Severity,
        { error, extra, piiFields = [] }: PublishReportOptions
    ) {
        const redactedExtraData = this.redactor.redact(extra ?? {}, {
            piiFields,
        });
        const report: Report = {
            severity,
            error,
            message,
            timestamp: new Date().toISOString(),
            extraData: redactedExtraData,
        };

        this.eventBus.publishReport(report);
    }
}
