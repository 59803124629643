import { BatchReporter, BatchReporterOptions } from './BatchReporter';

import { Report } from './types';
import { getContext } from '../util/getContext';

export class LoggingReporter extends BatchReporter {
    constructor(
        private readonly loggingApiUrl: string,
        options?: BatchReporterOptions
    ) {
        super(options);
    }

    async processReportBatch(reports: Report[]): Promise<void> {
        const body = JSON.stringify({
            Records: reports.map((r) => ({
                Data: {
                    '@timestamp': r.timestamp,
                    message: r.message,
                    severity: r.severity,
                    error: {
                        message: r.error?.message,
                        stack: r.error?.stack,
                    },
                    ...getContext(),
                },
            })),
        });
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST',
        };

        await fetch(this.loggingApiUrl, {
            method: 'POST',
            body,
            headers,
            keepalive: true,
        }).catch((e) => console.warn(e));
    }
}
