const genericError = new Error();
const defaultProps = Object.getOwnPropertyNames(genericError);

const extractExtraErrorProps = (error: Error) =>
    Object.fromEntries(
        Object.entries(error).filter<[string, unknown]>(
            (e): e is [string, unknown] => !defaultProps.includes(e[0])
        )
    );

export default extractExtraErrorProps;
