import { Report, Reporter, Severity } from './types';

export type BatchReporterOptions = {
    batchSize?: number;
    interval?: number;
};

export abstract class BatchReporter implements Reporter {
    private timeout: NodeJS.Timeout;

    protected reports: Report[] = [];
    protected interval: number;
    protected batchSize: number;

    public supportedSeverities: Severity[];

    constructor(options?: BatchReporterOptions) {
        const { batchSize = 10, interval = 10000 } = options ?? {};

        this.batchSize = batchSize;
        this.interval = interval;
    }

    public async processReport(report: Report): Promise<void> {
        this.reports.push(report);
        if (this.reports.length === this.batchSize) {
            await this.handleBatchProcess();
        } else {
            await this.queueReportTimer();
        }
    }

    public async teardown(): Promise<void> {
        clearTimeout(this.timeout);
        await this.handleBatchProcess();
    }

    private async queueReportTimer(): Promise<void> {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
            () => void this.handleBatchProcess.bind(this)(),
            this.interval
        );
    }

    private async handleBatchProcess(): Promise<void> {
        if (this.reports.length > 0) {
            const batchReports = this.reports;
            this.reports = [];
            await this.processReportBatch(batchReports);
        }
    }

    abstract processReportBatch(reports: Report[]): Promise<void>;
}
