import { Report } from './reporters';

const REPORT_EVENT_KEY = 'Report';

export class MonitorEventBus extends EventTarget {
    public publishReport(report: Report) {
        this.dispatchEvent(
            new CustomEvent<Report>(REPORT_EVENT_KEY, { detail: report })
        );
    }

    public addReportEventListener(handler: (e: CustomEvent<Report>) => void) {
        this.addEventListener(
            REPORT_EVENT_KEY,
            handler as EventListenerOrEventListenerObject
        );
    }

    public removeReportEventListener(
        handler: (e: CustomEvent<Report>) => void
    ) {
        this.removeEventListener(
            REPORT_EVENT_KEY,
            handler as EventListenerOrEventListenerObject
        );
    }
}
